<template>
  <div id="app">
    <router-view />
    <div class="footer__copyright">
      <p>© All Right Reserved.</p>
      <a href="mailto:info@tez.zp.ua">e-mail : info@tez.zp.ua</a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'App'
}
</script>
