import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage')
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('@/views/AboutPage')
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: () => import('@/views/ServicesPage')
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: () => import('@/views/ContactsPage')
  },
  {
    path: '/services/lightcar',
    name: 'LightCarPage',
    component: () => import('@/views/LightCarPage')
  },
  {
    path: '/services/heavycar',
    name: 'HeavyCarPage',
    component: () => import('@/views/HeavyCarPage')
  },
  {
    path: '/services/boats',
    name: 'BoatsPage',
    component: () => import('@/views/BoatsPage')
  },
  {
    path: '/services/buildings',
    name: 'BuildingsPage',
    component: () => import('@/views/BuildingsPage')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return {x: 0, y: 0}
  },
  routes
})

export default router
